import {
  find, reduce, map, orderBy,
} from 'lodash';
import { getMarketTemplateDisplayName, getMarketTemplateDisplayPosition } from '@/services/helpers/market-template-mapper';

const transformMarketConfigurations = (marketConfigurations, displayMarketsConfiguration) => reduce(
  marketConfigurations,
  (templates, marketConfiguration) => [
    ...templates,
    {
      marketTemplateId: marketConfiguration.sportLogicTemplate.marketTemplate.marketTemplateId,
      code: marketConfiguration.sportLogicTemplate.marketTemplate.template.code,
      marketCode: marketConfiguration.sportLogicTemplate.marketTemplate.template.marketCode,
      name: getMarketTemplateDisplayName(marketConfiguration.sportLogicTemplate.marketTemplate, displayMarketsConfiguration),
      displayConfiguration: marketConfiguration.displayConfiguration,
      enabled: marketConfiguration.enabled,
      offeringConfiguration: marketConfiguration.offeringConfiguration,
      resultingConfiguration: marketConfiguration.resultingConfiguration,
      cashoutConfiguration: marketConfiguration.cashoutConfiguration,
      sportLogicTemplate: marketConfiguration.sportLogicTemplate,
      sportsLogicTemplateId: marketConfiguration.sportsLogicTemplateId,
      templateConfiguration: marketConfiguration.templateConfiguration,
      limitConfiguration: marketConfiguration.limitConfiguration || marketConfiguration.sportLogicTemplate?.defaultLimitConfiguration,
      marginConfiguration: marketConfiguration.marginConfiguration || marketConfiguration.sportLogicTemplate?.defaultMarginConfiguration,
      template: marketConfiguration.sportLogicTemplate.marketTemplate.template,
      position: getMarketTemplateDisplayPosition(marketConfiguration.sportLogicTemplate.marketTemplate, displayMarketsConfiguration),
      disabledSelections: marketConfiguration.disabledSelections,
    },
  ],
  [],
);

const transformMarketTemplates = (marketTemplates, displayConfigurations) => reduce(
  marketTemplates,
  (templates, marketTemplate) => [
    ...templates,
    ...map(
      marketTemplate.sportLogicTemplates?.nodes || [],
      (sportLogicTemplate) => ({
        marketTemplateId: marketTemplate.template.marketTemplateId,
        code: marketTemplate.template.code,
        marketCode: marketTemplate.template.marketCode,
        name: getMarketTemplateDisplayName(marketTemplate, displayConfigurations),
        displayConfiguration: sportLogicTemplate.defaultDisplayConfiguration,
        enabled: true,
        offeringConfiguration: sportLogicTemplate.defaultOfferingConfiguration,
        resultingConfiguration: sportLogicTemplate.defaultResultingConfiguration,
        cashoutConfiguration: sportLogicTemplate.defaultCashoutConfiguration,
        sportLogicTemplate,
        sportsLogicTemplateId: sportLogicTemplate.sportLogicTemplateId,
        templateConfiguration: sportLogicTemplate.defaultTemplateConfiguration,
        limitConfiguration: sportLogicTemplate.defaultLimitConfiguration,
        marginConfiguration: sportLogicTemplate.defaultMarginConfiguration,
        template: marketTemplate.template,
        position: getMarketTemplateDisplayPosition(marketTemplate, displayConfigurations),
      }),
    ),
  ],
  [],
);

const filterInactiveMarketTemplates = (targetMarketTemplates, activeMarketTemplates) => reduce(
  targetMarketTemplates,
  (inactiveMarketTemplates, marketTemplate) => {
    const isActiveMarket = !!find(activeMarketTemplates, { marketTemplateId: marketTemplate.marketTemplateId });
    return isActiveMarket ? inactiveMarketTemplates : [
      ...inactiveMarketTemplates,
      marketTemplate,
    ];
  },
  [],
);

const orderMarketTemplates = (marketTemplates) => orderBy(marketTemplates, ['template.staticParams.PERIOD', 'template.staticParams.HALF', 'template.staticParams.TEAM',
  'template.staticParams.RESULT_AFTER_X', 'template.staticParams.RACE_TO', 'template.staticParams.SCORE_INDEX',
  'template.staticParams.DRIVE_INDEX', 'template.staticParams.BIN_MINUTE_START', 'template.staticParams.PUNT', 'template.staticParams.PLAY',
  'template.staticParams.FIELD_GOAL', 'template.staticParams.POSSESSION', 'template.staticParams.YARD_LINE', 'template.staticParams.PITCH_INDEX',
  'template.staticParams.PLATE_APPEARANCE', 'template.staticParams.IS_TOP_INNING', 'template.staticParams.TEAM_SCORE_AT_CREATION',
  'template.staticParams.HOME_SCORE_AT_CREATION', 'template.staticParams.AWAY_SCORE_AT_CREATION', 'template.staticParams.MILESTONE_YARDS']);

const createSportTemplateRequestPayload = (sportTemplate) => ({
  name: sportTemplate.name,
  sportId: sportTemplate.sport.sportId,
  sportTemplateId: sportTemplate.sportTemplateId,
  isDefault: sportTemplate.default,
  limitConfiguration: sportTemplate.limitconfiguration,
  marketConfigurations: reduce(
    sportTemplate.marketConfigurations,
    (serializedMarketConfigurations, rawMarketConfiguration) => {
      const {
        code,
        name,
        sportsLogicTemplateId,
        template,
        ...marketConfiguration
      } = rawMarketConfiguration;

      if (marketConfiguration?.sportLogicTemplate) {
        return {
          ...serializedMarketConfigurations,
          [sportsLogicTemplateId]: {
            ...marketConfiguration,
            sportLogicTemplate: {
              ...marketConfiguration?.sportLogicTemplate.template,
            },
          },
        };
      }

      return {
        ...serializedMarketConfigurations,
        [sportsLogicTemplateId]: marketConfiguration,
      };
    },
    {},
  ),
});

export const suspensionFlagsTabs = () => [
  {
    id: 1,
    label: 'Distance from the market',
    configurationType: 'priceDistanceConfiguration',
  },
  {
    id: 2,
    label: 'Arbitrage',
    configurationType: 'arbitrageConfiguration',
  },
  {
    id: 3,
    label: 'Best price',
    configurationType: 'topPriceConfiguration',
  },
];

const marketSegments = {
  AMERICAN_FOOTBALL: {
    FULL_TIME: {
      id: 'FULL_TIME',
      label: 'Full time',
    },
    HALF: {
      id: 'HALF',
      label: 'Half',
    },
    QUARTER_1: {
      id: 'QUARTER_1',
      label: 'Quarter 1',
    },
    QUARTER_3: {
      id: 'QUARTER_3',
      label: 'Quarter 3',
    },
    UNKNOWN: {
      id: 'UNKNOWN',
      label: 'Unknown',
    },
  },
  BASKETBALL: {
    FULL_TIME: {
      id: 'FULL_TIME',
      label: 'Full time',
    },
    HALF: {
      id: 'HALF',
      label: 'Half',
    },
    QUARTER_1: {
      id: 'QUARTER_1',
      label: 'Quarter 1',
    },
    QUARTER_3: {
      id: 'QUARTER_3',
      label: 'Quarter 3',
    },
    UNKNOWN: {
      id: 'UNKNOWN',
      label: 'Unknown',
    },
  },
  BASEBALL: {
    FULL_TIME: {
      id: 'FULL_TIME',
      label: 'Full time',
    },
    UNKNOWN: {
      id: 'UNKNOWN',
      label: 'Unknown',
    },
  },
  ICE_HOCKEY: {
    FULL_TIME: {
      id: 'FULL_TIME',
      label: 'Full time',
    },
    UNKNOWN: {
      id: 'UNKNOWN',
      label: 'Unknown',
    },
  },
};

export const suspensionFlagsConfigurationsPerType = {
  priceDistanceConfiguration: [
    {
      id: 'NO_CONFIG',
      label: 'No configuration',
      type: 'priceDistance',
    },
    {
      id: 'GLOBAL_DISTANCE',
      label: 'Global distance',
      type: 'priceDistance',
    },
    {
      id: 'GLOBAL_DISTANCE_EXTENDED',
      label: 'Global distance pregame/in-play',
      type: 'priceDistance',
    },
    {
      id: 'PER_MARKET_SEGMENT_DISTANCE',
      label: 'Distance per market segment',
      type: 'priceDistance',
    },
  ],
  arbitrageConfiguration: [
    {
      id: 'NO_CONFIG',
      label: 'No configuration',
      type: 'arbitragePrice',
    },
    {
      id: 'PER_MARKET_SEGMENT_ARBITRAGE',
      label: 'Arbitrage per market segment',
      type: 'arbitragePrice',
    },
  ],
  topPriceConfiguration: [
    {
      id: 'NO_CONFIG',
      label: 'No configuration',
      type: 'topPrice',
    },
    {
      id: 'PER_MARKET_SEGMENT_TOP_PRICE',
      label: 'Best price per market segment',
      type: 'topPrice',
    },
  ],
};

export const createDistanceColumns = (global) => {
  if (global === 'simple') {
    return [
      {
        id: 'distance',
        label: 'Distance',
        tooltip: 'Distance',
        type: 'input',
        position: 0,
      },
    ];
  }
  if (global === 'extended') {
    return [
      {
        id: 'preMatchDistance',
        label: 'Prematch',
        tooltip: 'Prematch distance',
        type: 'input',
        position: 0,
      },
      {
        id: 'inPlayDistance',
        label: 'In-play',
        tooltip: 'In-play distance',
        type: 'input',
        position: 1,
      },
      {
        id: 'preMatchSuspensionReason',
        label: 'PM SR',
        tooltip: 'Prematch suspension reason',
        type: 'dropdown',
        position: 2,
      },
      {
        id: 'inPlaySuspensionReason',
        label: 'IP SR',
        tooltip: 'In-play suspension reason',
        type: 'dropdown',
        position: 3,
      },
    ];
  }
  return [
    {
      id: 'marketSegment',
      label: 'Segment',
      tooltip: 'Market segment',
      type: 'label',
      position: 0,
    },
    {
      id: 'preMatchDistance',
      label: 'Prematch',
      tooltip: 'Prematch distance',
      type: 'input',
      position: 1,
    },
    {
      id: 'inPlayDistance',
      label: 'In-play',
      tooltip: 'In-play distance',
      type: 'input',
      position: 2,
    },
    {
      id: 'preMatchSuspensionReason',
      label: 'PM SR',
      tooltip: 'Prematch suspension reason',
      type: 'dropdown',
      position: 3,
    },
    {
      id: 'inPlaySuspensionReason',
      label: 'IP SR',
      tooltip: 'In-play suspension reason',
      type: 'dropdown',
      position: 4,
    },
  ];
};

export const createPriceColumns = () => [
  {
    id: 'marketSegment',
    label: 'Segment',
    tooltip: 'Market segment',
    type: 'label',
    position: 0,
  },
  {
    id: 'preMatchSuspensionAllowed',
    label: 'Prematch',
    tooltip: 'Prematch suspension allowed',
    type: 'toggle',
    position: 1,
  },
  {
    id: 'inPlaySuspensionAllowed',
    label: 'In-play',
    tooltip: 'In-play suspension allowed',
    type: 'toggle',
    position: 2,
  },
];

export const generateSportMarketSegments = (sportLabel) => marketSegments[sportLabel] || {};

export default {
  transformMarketConfigurations,
  transformMarketTemplates,
  filterInactiveMarketTemplates,
  orderMarketTemplates,
  createSportTemplateRequestPayload,
};
